import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './Sidebar.css';
import { thoat } from '../../../authSlice';

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickAccordion = (index) => {
    setActiveIndex((prevActiveIndex) =>
      prevActiveIndex === index ? null : index
    );
  };
  const isActive = (pathname) => {
    return location.pathname.startsWith(pathname);
  };

  useEffect(() => {
    contentRefs.current.forEach((content, index) => {
      content.style.height =
        activeIndex === index ? `${content.scrollHeight}px` : '0px';
    });
  }, [activeIndex]);

  const handleLogout = () => {
    localStorage.removeItem('result');
    dispatch(thoat());
    navigate('/');
  };
  return (
    <aside className="sidebarAdmin">
      <div className="sidebarAdmin-toggle">
        <div className="sidebarAdmin-logo">
          <h2>LeoDang</h2>
        </div>
        <div
          className="sidebarAdmin-close"
          id="close-btn"
          onClick={() => navigate(-1)}>
          <span className="material-icons-sharp">close</span>
        </div>
      </div>
      <div className="sidebarAdmin-sidebar">
        <Link
          className={`sidebarAdmin-link ${
            location.pathname === '/admin' ? 'active' : ''
          }`}
          to="/admin">
          <span className="material-icons-sharp">dashboard</span>
          <h3>Dashboard</h3>
        </Link>
        <Link
          className={`sidebarAdmin-link ${
            isActive('/admin/account') ? 'active' : ''
          }`}
          to="/admin/account">
          <span className="material-icons-sharp">person_outline</span>
          <h3>Users</h3>
        </Link>
        <div className="sidebarAdmin-accodion">
          <div
            className="sidebarAdmin-accodion-header"
            onClick={() => handleClickAccordion(0)}>
            <span className="material-icons-sharp">receipt_long</span>
            <h3>Layout</h3>
          </div>
          <div
            className="sidebarAdmin-accodion-content"
            ref={(el) => (contentRefs.current[0] = el)}>
            <Link
              to="/admin/header"
              className={`sidebarAdmin-accodion-link ${
                isActive('/admin/header') ? 'active' : ''
              }`}>
              <span className="material-icons-sharp">receipt_long</span>
              <h3>Header</h3>
            </Link>
            <Link
              to="/admin/about-page"
              className={`sidebarAdmin-accodion-link ${
                isActive('/admin/about-page') ? 'active' : ''
              }`}>
              <span className="material-icons-sharp">receipt_long</span>
              <h3>About Page</h3>
            </Link>
            <Link
              to="/admin/expertise-page"
              className={`sidebarAdmin-accodion-link ${
                isActive('/admin/expertise-page') ? 'active' : ''
              }`}>
              <span className="material-icons-sharp">receipt_long</span>
              <h3>Expertise Page</h3>
            </Link>
            <Link
              to="/admin/thoughts-page"
              className={`sidebarAdmin-accodion-link ${
                isActive('/admin/thoughts-page') ? 'active' : ''
              }`}>
              <span className="material-icons-sharp">receipt_long</span>
              <h3>Thoughts Page</h3>
            </Link>
            <Link
              to="/admin/footer"
              className={`sidebarAdmin-accodion-link ${
                isActive('/admin/footer') ? 'active' : ''
              }`}>
              <span className="material-icons-sharp">receipt_long</span>
              <h3>Footer</h3>
            </Link>
          </div>
        </div>
        <Link
          className={`sidebarAdmin-link ${
            isActive('/admin/contact') ? 'active' : ''
          }`}
          to="/admin/contact">
          <span className="material-icons-sharp">mail_outline</span>
          <h3>Contact</h3>
        </Link>
        <Link
          className={`sidebarAdmin-link ${
            isActive('/admin/cate-post') ? 'active' : ''
          }`}
          to="/admin/cate-post">
          <span className="material-icons-sharp">print</span>
          <h3>Category Post</h3>
        </Link>
        <Link
          className={`sidebarAdmin-link ${
            isActive('/admin/blog') ? 'active' : ''
          }`}
          to="/admin/blog">
          <span className="material-icons-sharp">paste</span>
          <h3>Blogs</h3>
        </Link>
        <Link
          className={`sidebarAdmin-link ${
            isActive('/admin/project-tag') ? 'active' : ''
          }`}
          to="/admin/project-tag">
          <span className="material-icons-sharp">tag</span>
          <h3>Project Tag</h3>
        </Link>
        <Link
          className={`sidebarAdmin-link ${
            isActive('/admin/projects') ? 'active' : ''
          }`}
          to="/admin/projects">
          <span className="material-icons-sharp">inventory</span>
          <h3>Projects</h3>
        </Link>
        <Link
          className={`sidebarAdmin-link ${
            isActive('/admin/new-login') ? 'active' : ''
          }`}
          to="/admin/new-login">
          <span className="material-icons-sharp">add</span>
          <h3>New Login</h3>
        </Link>
        <Link
          className="sidebarAdmin-link"
          href="/"
          onClick={handleLogout}>
          <span className="material-icons-sharp">logout</span>
          <h3>Logout</h3>
        </Link>
      </div>
    </aside>
  );
};

export default Sidebar;
