import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import UserApp from './User/App';
import AdminApp from './Admin/App';
import LoginPage from './LoginPage';
import { useSelector } from 'react-redux';
import { ProtectedRoute } from './ProtectedRoute';
import { useDispatch } from 'react-redux';
import { dalogin } from './authSlice';
import ForgotPassword from './ForgotPassword';

function App() {
  const [loading, setLoading] = useState(false);
  const [headerMeta, setHeaderMeta] = useState({});
  const updateFavicon = (faviconUrl) => {
    let link = document.querySelector("link[rel*='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = faviconUrl;
  };

  useEffect(() => {
    const fetchHeaderMeta = async () => {
      const res = await fetch('https://api.thechuck.vn/header/get-meta');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setHeaderMeta(data);
      updateFavicon(data.icon);
      setLoading(true);
    };
    fetchHeaderMeta();
  }, []);

  const userIsAuthenticated = useSelector((state) => state.auth.daDangNhap);
  const dispatch = useDispatch();

  useEffect(() => {
    // Kiểm tra localStorage khi ứng dụng khởi động
    const result = localStorage.getItem('result');
    if (result) {
      // Chuyển đổi chuỗi JSON trở lại thành đối tượng và đưa vào Redux store
      dispatch(dalogin(JSON.parse(result)));
    }
  }, [dispatch]);

  return (
    <Router>
      {loading && (
        <Routes>
          <Route
            path="/login"
            element={<LoginPage />}
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword />}
          />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/admin/*"
              element={
                userIsAuthenticated ? (
                  <AdminApp />
                ) : (
                  <Navigate
                    replace
                    to="/login"
                  />
                )
              }
            />
          </Route>
          <Route
            path="/*"
            element={<UserApp headerMeta={headerMeta} />}
          />
        </Routes>
      )}
    </Router>
  );
}

export default App;
