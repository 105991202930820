import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../../server/uploads.js';
import cloudinaryDelete from '../../../../server/delete.js';
import * as yup from 'yup';

const schema = yup.object({
  image: yup.string().required('Không được bỏ trống'),
});
const FormEditWIM = ({ onRefresh, wimItem }) => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedImagePublicId, setUploadedImagePublicId] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [uploadOption, setUploadOption] = useState('file');
  const [isLoaded, setIsLoaded] = useState(false);

  const form = useForm({
    defaultValues: {
      image: wimItem.image,
      id_image: wimItem.id_image,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const fetchLogo = useCallback(async () => {
    try {
      const res = await fetch(
        `https://api.thechuck.vn/about/get-about-wim/${wimItem.about_wim_id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setValue('image', data.image);
      setValue('id_image', data.id_image);
    } catch (error) {
      console.error('Error fetching project tag details:', error);
    }
  }, [setValue, wimItem.about_wim_id]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onRefresh();
      fetchLogo();
      reset({ ...wimItem });
    }
  }, [isSubmitSuccessful, fetchLogo, onRefresh, reset, wimItem]);

  const handleFileUpload = async (e) => {
    setIsLoaded(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageUrl(res.secure_url);
      setUploadedImagePublicId(res.publicId);
      setValue('image', res.secure_url);
      setValue('id_image', res.publicId);
      setIsImageUploaded(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleDeleteImage = async () => {
    if (!uploadedImagePublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImagePublicId);
      setUploadedImageUrl('');
      setUploadedImagePublicId('');
      setIsImageUploaded(false);
      setValue('image', '');
      setIsLoaded(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleUploadOptionChange = (e) => {
    setUploadOption(e.target.value);
  };
  const extractSrcFromIframe = (iframeString) => {
    const srcMatch = iframeString.match(
      /src\s*=\s*"([^"]+)"|src\s*=\s*'([^']+)'/
    );
    return srcMatch ? srcMatch[1] || srcMatch[2] : null;
  };

  const handleInputChange = (e) => {
    const embedCode = e.target.value;
    const url = extractSrcFromIframe(embedCode);
    setValue('iamge', url);
  };
  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const handleSubmitProject = async (data) => {
    if (isImageUploaded) {
      cloudinaryDelete(wimItem.id_image);
    }
    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        function (char) {
          return '&#x' + char.codePointAt(0).toString(16) + ';';
        }
      );
    }
    function convertObjectToUnicode(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]); // Nếu phần tử là một đối tượng, gọi đệ quy
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]); // Nếu phần tử là một chuỗi, chuyển đổi thành Unicode
        }
      }
      return obj;
    }

    const convertedData = convertObjectToUnicode(data);
    try {
      const url = `https://api.thechuck.vn/about/update-about-wim/${wimItem.about_wim_id}`;
      const opt = {
        method: 'put',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi thêm sản phẩm: ', error);
    }
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Hình Ảnh</label>
              <select onChange={(e) => handleUploadOptionChange(e)}>
                <option value="file">Tải lên từ máy</option>
                <option value="url">Nhập link từ YouTube</option>
              </select>
              {uploadOption === 'file' ? (
                <input
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                  accept="image/*,video/*"
                />
              ) : (
                <input
                  type="text"
                  placeholder="Nhập mã nhúng từ YouTube"
                  onChange={handleInputChange}
                />
              )}
              {errors.logo && <p className="error">{errors.logo.message}</p>}
            </div>
            <div className="form-project-flex-item">
              {/* Hiển thị hình ảnh đã tải lên */}
              {isLoaded ? (
                isImageUploaded && isImageUploaded ? (
                  <div className="form-project-flex-img">
                    {checkURL(uploadedImageUrl) ? (
                      <img
                        src={uploadedImageUrl}
                        title="Project Image"
                        alt="Project_Image"
                      />
                    ) : (
                      <iframe
                        src={uploadedImageUrl}
                        title="Project Video"
                        alt="Project_Video"
                      />
                    )}
                    <p onClick={handleDeleteImage}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : (
                <div className="form-project-flex-img">
                  {checkURL(wimItem.image) ? (
                    <img
                      src={wimItem.image}
                      title="Project Image"
                      alt="Project_Image"
                    />
                  ) : isValidYoutubeEmbedUrl(wimItem.image) ? (
                    <iframe
                      src={wimItem.image}
                      title="Project Video"
                      alt="Project_Video"
                    />
                  ) : (
                    <iframe
                      src={wimItem.image}
                      title="Project Video"
                      alt="Project_Video"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormEditWIM;
