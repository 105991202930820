import React from 'react';
import LineChar from '../LineChar/LineChar';

const Home = () => {
  return (
    <div>
      <LineChar />
    </div>
  );
};

export default Home;
