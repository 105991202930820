import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Accout = () => {
  const [listUser, setListUser] = useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchAccout = async () => {
      const res = await fetch(`https://api.thechuck.vn/users/list`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setListUser(data);
    };
    fetchAccout();
  }, []);

  const deleteProject = (id) => {
    if (user?.id_user === id) {
      alert('Không thể xóa tài khoản của chính mình');
      return;
    }
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(`https://api.thechuck.vn/users/delete/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch('https://api.thechuck.vn/users/list');
            const data = await res.json();
            setListUser(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div class="recent-orders">
      <h2>Danh sách tài khoản</h2>
      <table>
        <thead>
          <tr>
            <th>Tên tài khoản</th>
            <th>Email</th>
            <th>Số điện thoại</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {listUser.map((user, i) => (
            <tr key={i}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.phone}</td>
              <td></td>
              <td>
                <p
                  className="project-actions"
                  onClick={() => deleteProject(user.id_user)}>
                  Xóa
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Accout;
