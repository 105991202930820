import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import './LineChar.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChar = () => {
  const [viewSevenDays, setViewSevenDays] = useState([]);
  const [totalViews, setTotalViews] = useState({});

  useEffect(() => {
    const fetchViews = async () => {
      try {
        const res = await fetch('https://api.thechuck.vn/page/seven-days');
        const data = await res.json();
        setViewSevenDays(data);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchTotalViews = async () => {
      try {
        const res = await fetch('https://api.thechuck.vn/page/total-views');
        const data = await res.json();
        setTotalViews(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTotalViews();
    fetchViews();
  }, []);

  const reversedViewSevenDays = [...viewSevenDays].reverse();
  const labels = reversedViewSevenDays.map((item) =>
    format(new Date(item.day), 'dd/MM')
  );
  const viewsData = reversedViewSevenDays.map(
    (item) =>
      item.work + item.blog + item.about + item.expertise + item.thoughts
  );
  console.log(labels, viewsData);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Lượt truy cập trong 7 ngày gần đây',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: '7 Ngày Gần Đây',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Views',
        },
        min: 0,
        max: 100,
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Views',
        data: viewsData,
        borderColor: '#e63f30',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: true,
      },
    ],
  };

  return (
    <>
      <Line
        options={options}
        data={data}
      />
      <div className="line-views">
        <div className="line-table">
          <h3>Tổng quan</h3>
          <p>Work: {totalViews.total_work} views</p>
          <p>About: {totalViews.total_about} views</p>
          <p>Blog: {totalViews.total_blog} views</p>
          <p>Expertise: {totalViews.total_expertise} views</p>
          <p>Thoughts: {totalViews.total_thought} views</p>
        </div>
      </div>
    </>
  );
};

export default LineChar;
