import React, { useEffect, useState } from 'react';
import FormAddCate from './FormAddCate/FormAddCate';
import FormEditCate from './FormAddCate/FormEditCate';

const CatePost = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [openFormCate, setOpenFormCate] = useState(false);
  const [openFormEditCate, setOpenFormEditCate] = useState(false);
  const [catePost, setCatePost] = useState({});

  const handleOpenFormCate = () => {
    setOpenFormCate(true);
  };
  const handleCloseFormCate = () => {
    setOpenFormCate(false);
  };

  const handleOpenFormEditCate = (catePost) => {
    setOpenFormEditCate(true);
    setCatePost(catePost);
  };
  const handleCloseFormEditCate = () => {
    setOpenFormEditCate(false);
  };

  useEffect(() => {
    const fetchProjectTag = async () => {
      const res = await fetch(`https://api.thechuck.vn/blog/get-cate-blog`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCategoryList(data);
    };
    fetchProjectTag();
    setOpenFormCate(false);
    setOpenFormEditCate(false);
  }, [refreshKey]);

  const deleteCatePost = (post) => {
    if (window.confirm('Bạn muốn xóa mục này không?')) {
      fetch(
        `https://api.thechuck.vn/blog/delete-cate-blog/${post.cate_post_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              'https://api.thechuck.vn/blog/get-cate-blog'
            );
            const data = await res.json();
            setCategoryList(data);
          };
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  return (
    <div class="recent-orders">
      <h1>Danh mục bài viết</h1>
      {openFormEditCate ? (
        <>
          <FormEditCate
            post={catePost}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditCate}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên mục</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {categoryList.map((tag, index) => (
            <tr key={index}>
              <td>
                <p dangerouslySetInnerHTML={{ __html: tag.name }} />
              </td>
              <td>{tag.status === 1 ? 'Hiện' : 'Ẩn'}</td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditCate(tag)}>
                  Sửa
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteCatePost(tag)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="project-add">
        <span onClick={handleOpenFormCate}>Thêm mục mới</span>
      </div>
      {openFormCate ? (
        <>
          <FormAddCate onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormCate}>Đóng</span>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CatePost;
