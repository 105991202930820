import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  description: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
});

const FormDesc = ({ onRefresh, item }) => {
  const form = useForm({
    defaultValues: {
      description: item.description,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const fetchInfoData = useCallback(async () => {
    try {
      const res = await fetch(`https://api.thechuck.vn/header/get-meta`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setValue('description', data.description);
    } catch (error) {
      console.error('Failed to fetch project data:', error);
    }
  }, [setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onRefresh();
      fetchInfoData();
      reset({ ...item });
    }
  }, [isSubmitSuccessful, onRefresh, reset, item, fetchInfoData]);

  const handleSubmitProject = async (data) => {
    try {
      const url = `https://api.thechuck.vn/header/update-meta-desc`;
      const opt = {
        method: 'put',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <label>Nội dung môi tả</label>
          <textarea
            type="text"
            placeholder="Nội dung"
            rows="7"
            cols="80"
            {...register('description')}
          />
          {errors.description && (
            <p className="error">{errors.description.message}</p>
          )}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormDesc;
