import React, { useEffect, useState } from 'react';
import './Projects.css';
import FormAddProject from './FormProjects/FormAddProject';
import ReactPaginate from 'react-paginate';
import cloudinaryDelete from '../../../server/delete.js';
import FormEditProject from './FormProjects/FormEditProject.jsx';

const Projects = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [openFormProject, setOpenFormProject] = useState(false);
  const [openFormEditProject, setOpenFormEditProject] = useState(false);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({});

  const handleOpenFormProject = () => {
    setOpenFormProject(true);
  };
  const handleCloseFormProject = () => {
    setOpenFormProject(false);
  };
  const handleOpenFormEditProject = (project) => {
    setOpenFormEditProject(true);
    setProject(project);
  };
  const handleCloseFormEditProject = () => {
    setOpenFormEditProject(false);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch('https://api.thechuck.vn/projects/get-projects');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setProjects(data);
    };
    fetchProjects();
    setOpenFormProject(false);
  }, [refreshKey]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const deleteProject = (project) => {
    if (window.confirm('Bạn muốn xóa project này không?')) {
      fetch(
        `https://api.thechuck.vn/projects/delete-project/${project.project_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              'https://api.thechuck.vn/projects/get-projects'
            );
            const data = await res.json();
            setProjects(data);
          };
          cloudinaryDelete(project.id_thumbnail);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  function LayoutInPage({ itemInPage }) {
    return (
      <tbody className="project-list">
        {itemInPage &&
          itemInPage.slice(0, 10).map((project) => (
            <tr>
              <td>
                <div dangerouslySetInnerHTML={{ __html: project.heading }} />
              </td>
              <td>
                {checkURL(project.thumbnail) ? (
                  <img
                    src={project.thumbnail}
                    title="Project Image"
                    alt="ProjectImage"
                    className="project-image"
                  />
                ) : isValidYoutubeEmbedUrl(project.thumbnail) ? (
                  <iframe
                    src={project.thumbnail}
                    title="Project Video"
                    alt="ProjectImage"
                    className="project-image"
                  />
                ) : (
                  <iframe
                    src={project.thumbnail}
                    title="Project Video"
                    alt="ProjectImage"
                    className="project-image"
                  />
                )}
              </td>
              <td>
                {project.status === 1
                  ? 'Hoàn thành'
                  : project.status === 2
                  ? 'Ẩn'
                  : 'Coming soon'}
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditProject(project)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteProject(project)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
      </tbody>
    );
  }
  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(projects.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={projects.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="project-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return (
    <div class="recent-orders">
      <h1>Dự án</h1>
      {openFormEditProject ? (
        <>
          <FormEditProject
            project={project}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditProject}>Đóng</span>
          </div>
        </>
      ) : null}
      <h2>Danh sách dự án</h2>
      <table>
        <thead>
          <tr>
            <th>Tên dự án</th>
            <th>Hình thumbnail</th>
            <th>Trạng thái</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <PageItem pageSize={10} />
      </table>
      <div className="project-add">
        <span onClick={handleOpenFormProject}>Thêm dự án mới</span>
      </div>
      {openFormProject ? (
        <>
          <FormAddProject onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormProject}>Đóng</span>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Projects;
