import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  item_1: yup.string().required('Không được bỏ trống'),
  item_2: yup.string().required('Không được bỏ trống'),
  item_3: yup.string().required('Không được bỏ trống'),
  item_4: yup.string().required('Không được bỏ trống'),
  item_5: yup.string().required('Không được bỏ trống'),
});

const FormMenu = ({ menu, onRefresh }) => {
  const form = useForm({
    defaultValues: {
      item_1: menu.item_1,
      item_2: menu.item_2,
      item_3: menu.item_3,
      item_4: menu.item_4,
      item_5: menu.item_5,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const fetchInfoData = useCallback(async () => {
    try {
      const res = await fetch(`https://api.thechuck.vn/header/get-menu`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setValue('item_1', data.item_1);
      setValue('item_2', data.item_2);
      setValue('item_3', data.item_3);
      setValue('item_4', data.item_4);
      setValue('item_5', data.item_5);
    } catch (error) {
      console.error('Failed to fetch project data:', error);
    }
  }, [setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onRefresh();
      fetchInfoData();
      reset({ ...menu });
    }
  }, [isSubmitSuccessful, onRefresh, reset, menu, fetchInfoData]);

  const handleSubmitProject = async (data) => {
    try {
      const url = `https://api.thechuck.vn/header/update-menu`;
      const opt = {
        method: 'put',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('item_1')}
          />
          {errors.item_1 && <p className="error">{errors.item_1.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('item_2')}
          />
          {errors.item_2 && <p className="error">{errors.item_2.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('item_3')}
          />
          {errors.item_3 && <p className="error">{errors.item_3.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('item_4')}
          />
          {errors.item_4 && <p className="error">{errors.item_4.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('item_5')}
          />
          {errors.item_5 && <p className="error">{errors.item_5.message}</p>}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormMenu;
