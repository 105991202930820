import React, { useEffect, useState } from 'react';
import FormThoughtBanne from './FormThought/FormThoughtBanne';
import FormThoughtInfo from './FormThought/FormThoughtInfo';
import FormAdd from './FormMarquee/FormAdd';
import FormEdit from './FormMarquee/FormEdit';
import FormThoughtImage from './FormThought/FormThoughtImage';

const Thought = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [banner, setBanner] = useState({});
  const [info, setInfo] = useState({});
  const [image, setImage] = useState({});
  const [title, setTitle] = useState('');
  const [listMarquee, setListMarquee] = useState([]);
  const [formBanner, setFormBanner] = useState(false);
  const [formInfomation, setFormInfomation] = useState(false);
  const [formMarquee, setFormMarquee] = useState(false);
  const [formEditMarquee, setFormEditMarquee] = useState(false);
  const [formImage, setFormImage] = useState(false);

  const handleOpenFormBanner = () => {
    setFormBanner(true);
    setFormImage(false);
    setFormInfomation(false);
  };
  const handleCloseFormBanner = () => {
    setFormBanner(false);
  };

  const handleOpenFormInfomation = () => {
    setFormInfomation(true);
    setFormBanner(false);
    setFormImage(false);
  };
  const handleCloseFormInfomation = () => {
    setFormInfomation(false);
  };

  const handleOpenFormMarquee = () => {
    setFormMarquee(true);
  };
  const handleCloseFormMarquee = () => {
    setFormMarquee(false);
  };
  const handleOpenFormEditMarquee = (title) => {
    setFormEditMarquee(true);
    setTitle(title);
  };
  const handleCloseFormEditMarquee = () => {
    setFormEditMarquee(false);
  };

  const handleOpenFormImage = () => {
    setFormImage(true);
    setFormBanner(false);
    setFormInfomation(false);
  };
  const handleCloseFormImage = () => {
    setFormImage(false);
  };

  useEffect(() => {
    const fetchBanner = async () => {
      const res = await fetch('https://api.thechuck.vn/thought/get-banner');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBanner(data);
    };
    const fetchMarquee = async () => {
      const res = await fetch('https://api.thechuck.vn/thought/get-marquee');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setListMarquee(data);
    };
    const fetchInfo = async () => {
      const res = await fetch('https://api.thechuck.vn/thought/get-info');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setInfo(data);
    };
    const fetchImage = async () => {
      const res = await fetch('https://api.thechuck.vn/thought/get-image');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setImage(data);
    };

    fetchInfo();
    fetchBanner();
    fetchImage();
    fetchMarquee();
  }, [refreshKey]);

  const deleteProject = (title) => {
    if (window.confirm('Bạn muốn xóa tiêu đề này không?')) {
      fetch(
        `https://api.thechuck.vn/thought/delete-marquee/${title.thought_marquee_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              'https://api.thechuck.vn/thought/get-marquee'
            );
            const data = await res.json();
            setListMarquee(data);
          };
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div class="recent-orders">
      <h1>Thought</h1>
      {formBanner ? (
        <>
          <FormThoughtBanne
            thoughtBanner={banner}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormBanner}>Đóng</span>
          </div>
        </>
      ) : null}
      {formInfomation ? (
        <>
          <FormThoughtInfo
            info={info}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormInfomation}>Đóng</span>
          </div>
        </>
      ) : null}
      {formImage ? (
        <>
          <FormThoughtImage
            image={image}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormImage}>Đóng</span>
          </div>
        </>
      ) : null}
      <h2>Danh sách secsion</h2>
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Banner</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormBanner}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Infomation</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormInfomation}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Image</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormImage}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Danh tiêu đề</h2>
      {formEditMarquee ? (
        <>
          <FormEdit
            title={title}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditMarquee}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên tiêu đề</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {listMarquee.map((item) => (
            <tr>
              <td>
                <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditMarquee(item)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteProject(item)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formMarquee ? (
        <>
          <FormAdd onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormMarquee}>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-add">
        <span onClick={handleOpenFormMarquee}>Thêm tiêu đề mới</span>
      </div>
    </div>
  );
};

export default Thought;
