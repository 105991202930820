import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  social_1: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
  link_1: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
  social_2: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
  link_2: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
  social_3: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
  link_3: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
});

const FormSocial = ({ onRefresh, item }) => {
  const form = useForm({
    defaultValues: {
      social_1: item.social_1,
      link_1: item.link_1,
      social_2: item.social_2,
      link_2: item.link_2,
      social_3: item.social_3,
      link_3: item.link_3,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const fetchInfoData = useCallback(async () => {
    try {
      const res = await fetch(`https://api.thechuck.vn/header/get-social`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setValue('social_1', data.social_1);
      setValue('link_1', data.link_1);
      setValue('social_2', data.social_2);
      setValue('link_2', data.link_2);
      setValue('social_3', data.social_3);
      setValue('link_3', data.link_3);
    } catch (error) {
      console.error('Failed to fetch project data:', error);
    }
  }, [setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onRefresh();
      fetchInfoData();
      reset({ ...item });
    }
  }, [isSubmitSuccessful, onRefresh, reset, item, fetchInfoData]);

  const handleSubmitProject = async (data) => {
    try {
      const url = `https://api.thechuck.vn/header/update-social`;
      const opt = {
        method: 'put',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            placeholder="Tên menu"
            {...register('social_1')}
          />
          {errors.social_1 && (
            <p className="error">{errors.social_1.message}</p>
          )}
        </div>
        <div className="form-project-item">
          <label>Link</label>
          <textarea
            type="text"
            placeholder="Link"
            rows="7"
            cols="80"
            {...register('link_1')}
          />
          {errors.link_1 && <p className="error">{errors.link_1.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            placeholder="Tên menu"
            {...register('social_2')}
          />
          {errors.social_2 && (
            <p className="error">{errors.social_2.message}</p>
          )}
        </div>
        <div className="form-project-item">
          <label>Link</label>
          <textarea
            type="text"
            placeholder="Link"
            rows="7"
            cols="80"
            {...register('link_2')}
          />
          {errors.link_2 && <p className="error">{errors.link_2.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            placeholder="Tên menu"
            {...register('social_3')}
          />
          {errors.social_3 && (
            <p className="error">{errors.social_3.message}</p>
          )}
        </div>
        <div className="form-project-item">
          <label>Link</label>
          <textarea
            type="text"
            placeholder="Link"
            rows="7"
            cols="80"
            {...register('link_3')}
          />
          {errors.link_3 && <p className="error">{errors.link_3.message}</p>}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormSocial;
