import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Components/Home/Home';
import Sidebar from './Components/Sidebar/Sidebar';
import Nav from './Components/Nav/Nav';
import Projects from './Components/Projects/Projects';
import ProjectTag from './Components/ProjectTag/ProjectTag';
import CatePost from './Components/CatePost/CatePost';
import Blog from './Components/Blog/Blog';
import Thought from './Components/Thought/Thought';
import Expertise from './Components/Expertise/Expertise';
import About from './Components/About/About';
import Header from './Components/Header/Header';
import Profile from './Components/Profile/Profile';
import Register from './Components/Register/Register';
import UpdatePass from './Components/Accout/UpdatePass';
import Accout from './Components/Accout/Accout';
import Contact from './Components/Contact/Contact';

const App = () => {
  useEffect(() => {
    document.title = 'Admin - Leo Dang';
  }, []);
  return (
    <div className="wrapper-admin">
      <div className="container-admin">
        <Sidebar />
        <main className="mainAdmin">
          <Nav />
          <Routes>
            <Route
              path="/"
              element={<Home />}
            />
            <Route
              path="/projects"
              element={<Projects />}
            />
            <Route
              path="/project-tag"
              element={<ProjectTag />}
            />
            <Route
              path="/cate-post"
              element={<CatePost />}
            />
            <Route
              path="/account"
              element={<Accout />}
            />
            <Route
              path="/blog"
              element={<Blog />}
            />
            <Route
              path="/header"
              element={<Header />}
            />
            <Route
              path="/about-page"
              element={<About />}
            />
            <Route
              path="/contact"
              element={<Contact />}
            />
            <Route
              path="/thoughts-page"
              element={<Thought />}
            />
            <Route
              path="/expertise-page"
              element={<Expertise />}
            />
            <Route
              path="/profile/:id"
              element={<Profile />}
            />
            <Route
              path="/new-login"
              element={<Register />}
            />
            <Route
              path="/reset-password"
              element={<UpdatePass />}
            />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default App;
