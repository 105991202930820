import React, { useEffect, useState } from 'react';
import cloudinaryDelete from '../../../server/delete.js';
import FormBanner from './FormAbout/FormBanner';
import FormInfo from './FormAbout/FormInfo';
import FormTitleWID from './FormAbout/FormTitleWID';
import FormTitleWIL from './FormAbout/FormTitleWIL';
import FormTitleWIS from './FormAbout/FormTitleWIS';
import FormWIM from './FormAbout/FormWIM';
import FormAddWID from './FormWID/FormAddWID';
import FormEditWID from './FormWID/FormEditWID';
import FormAddWIL from './FormWIL/FormAddWIL';
import FormEditWIL from './FormWIL/FormEditWIL';
import FormAddWIS from './FormWIS/FormAddWIS';
import FormEditWIS from './FormWIS/FormEditWIS.jsx';
import FormAddWIM from './FormWIM/FormAddWIM.jsx';
import FormEditWIM from './FormWIM/FormEditWIM.jsx';
import FormImage from './FormAbout/FormImage.jsx';

const About = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [about, setAbout] = useState({});
  const [formBanner, setFormBanner] = useState(false);
  const [formInfo, setFormInfo] = useState(false);
  const [formWid, setFormWid] = useState(false);
  const [formAddWid, setFormAddWid] = useState(false);
  const [formEditWid, setFormEditWid] = useState(false);
  const [widList, setWidList] = useState([]);
  const [wid, setWid] = useState({});
  const [formWil, setFormWil] = useState(false);
  const [formAddWil, setFormAddWil] = useState(false);
  const [formEditWil, setFormEditWil] = useState(false);
  const [wilList, setWilList] = useState([]);
  const [wil, setWil] = useState({});
  const [formWis, setFormWis] = useState(false);
  const [formAddWis, setFormAddWis] = useState(false);
  const [formEditWis, setFormEditWis] = useState(false);
  const [wisList, setWisList] = useState([]);
  const [wis, setWis] = useState({});
  const [formWim, setFormWim] = useState(false);
  const [formAddWim, setFormAddWim] = useState(false);
  const [formEditWim, setFormEditWim] = useState(false);
  const [wimList, setWimList] = useState([]);
  const [wim, setWim] = useState({});
  const [formImage, setFormImage] = useState(false);
  const [image, setImage] = useState({});

  const handleOpenFormBanner = () => {
    setFormBanner(true);
    setFormInfo(false);
    setFormWid(false);
    setFormWil(false);
    setFormWis(false);
    setFormWim(false);
    setFormImage(false);
  };
  const handleCloseFormBrand = () => {
    setFormBanner(false);
  };

  const handleOpenFormInfo = () => {
    setFormInfo(true);
    setFormBanner(false);
    setFormWid(false);
    setFormWil(false);
    setFormWis(false);
    setFormWim(false);
    setFormImage(false);
  };
  const handleCloseFormInfo = () => {
    setFormInfo(false);
  };

  const handleOpenFormWid = () => {
    setFormWid(true);
    setFormBanner(false);
    setFormInfo(false);
    setFormWil(false);
    setFormWis(false);
    setFormWim(false);
    setFormImage(false);
  };
  const handleCloseFormWid = () => {
    setFormWid(false);
  };
  const handleOpenFormAddWid = () => {
    setFormAddWid(true);
  };
  const handleCloseFormAddWid = () => {
    setFormAddWid(false);
  };

  const handleOpenFormWil = () => {
    setFormWil(true);
    setFormBanner(false);
    setFormInfo(false);
    setFormWid(false);
    setFormWis(false);
    setFormWim(false);
    setFormImage(false);
  };
  const handleCloseFormWil = () => {
    setFormWil(false);
  };
  const handleOpenFormAddWil = () => {
    setFormAddWil(true);
  };
  const handleCloseFormAddWil = () => {
    setFormAddWil(false);
  };

  const handleOpenFormWis = () => {
    setFormWis(true);
    setFormBanner(false);
    setFormInfo(false);
    setFormWid(false);
    setFormWil(false);
    setFormWim(false);
    setFormImage(false);
  };
  const handleCloseFormWis = () => {
    setFormWis(false);
  };
  const handleOpenFormAddWis = () => {
    setFormAddWis(true);
  };
  const handleCloseFormAddWis = () => {
    setFormAddWis(false);
  };

  const handleOpenFormWim = () => {
    setFormWim(true);
    setFormBanner(false);
    setFormInfo(false);
    setFormWid(false);
    setFormWil(false);
    setFormWis(false);
    setFormImage(false);
  };
  const handleCloseFormWim = () => {
    setFormWim(false);
  };
  const handleOpenFormAddWim = () => {
    setFormAddWim(true);
  };
  const handleCloseFormAddWim = () => {
    setFormAddWim(false);
  };

  const handleOpenFormImage = () => {
    setFormImage(true);
    setFormBanner(false);
    setFormInfo(false);
    setFormWid(false);
    setFormWil(false);
    setFormWis(false);
    setFormWim(false);
  };
  const handleCloseFormImage = () => {
    setFormImage(false);
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const res = await fetch('https://api.thechuck.vn/about/get-about');
        const text = await res.text();
        const data = text ? JSON.parse(text) : {};
        setAbout(data);
      } catch (error) {
        console.error('Error fetching about:', error);
      }
    };
    const fetchAboutWid = async () => {
      try {
        const res = await fetch('https://api.thechuck.vn/about/get-about-wid');
        const text = await res.text();
        const data = text ? JSON.parse(text) : {};
        setWidList(data);
      } catch (error) {
        console.error('Error fetching about:', error);
      }
    };
    const fetchAboutWis = async () => {
      try {
        const res = await fetch('https://api.thechuck.vn/about/get-about-wis');
        const text = await res.text();
        const data = text ? JSON.parse(text) : {};
        setWisList(data);
      } catch (error) {
        console.error('Error fetching about:', error);
      }
    };
    const fetchAboutWil = async () => {
      try {
        const res = await fetch('https://api.thechuck.vn/about/get-about-wil');
        const text = await res.text();
        const data = text ? JSON.parse(text) : {};
        setWilList(data);
      } catch (error) {
        console.error('Error fetching about:', error);
      }
    };
    const fetchAboutWim = async () => {
      try {
        const res = await fetch('https://api.thechuck.vn/about/get-about-wim');
        const text = await res.text();
        const data = text ? JSON.parse(text) : {};
        setWimList(data);
      } catch (error) {
        console.error('Error fetching about:', error);
      }
    };
    const fetchAboutImage = async () => {
      try {
        const res = await fetch('https://api.thechuck.vn/about/get-image');
        const text = await res.text();
        const data = text ? JSON.parse(text) : {};
        setImage(data);
      } catch (error) {
        console.error('Error fetching about:', error);
      }
    };

    fetchAbout();
    fetchAboutWid();
    fetchAboutWil();
    fetchAboutWis();
    fetchAboutWim();
    fetchAboutImage();
  }, [refreshKey]);

  const deleteWid = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(
        `https://api.thechuck.vn/about/delete-about-wid/${item.about_wid_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchAboutWid = async () => {
            try {
              const res = await fetch(
                'https://api.thechuck.vn/about/get-about-wid'
              );
              const text = await res.text();
              const data = text ? JSON.parse(text) : {};
              setWidList(data);
            } catch (error) {
              console.error('Error fetching about:', error);
            }
          };

          fetchAboutWid();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const deleteWil = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(
        `https://api.thechuck.vn/about/delete-about-wil/${item.about_wil_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchAboutWil = async () => {
            try {
              const res = await fetch(
                'https://api.thechuck.vn/about/get-about-wil'
              );
              const text = await res.text();
              const data = text ? JSON.parse(text) : {};
              setWilList(data);
            } catch (error) {
              console.error('Error fetching about:', error);
            }
          };

          fetchAboutWil();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const deleteWis = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      cloudinaryDelete(item.id_image);
      fetch(
        `https://api.thechuck.vn/about/delete-about-wis/${item.about_wis_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchAboutWis = async () => {
            try {
              const res = await fetch(
                'https://api.thechuck.vn/about/get-about-wis'
              );
              const text = await res.text();
              const data = text ? JSON.parse(text) : {};
              setWisList(data);
            } catch (error) {
              console.error('Error fetching about:', error);
            }
          };

          fetchAboutWis();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const deleteWim = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      cloudinaryDelete(item.id_image);
      fetch(
        `https://api.thechuck.vn/about/delete-about-wim/${item.about_wim_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchAboutWim = async () => {
            try {
              const res = await fetch(
                'https://api.thechuck.vn/about/get-about-wim'
              );
              const text = await res.text();
              const data = text ? JSON.parse(text) : {};
              setWimList(data);
            } catch (error) {
              console.error('Error fetching about:', error);
            }
          };

          fetchAboutWim();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div class="recent-orders">
      <h1>About</h1>
      {formBanner ? (
        <>
          <FormBanner
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
            banner={about}
          />
          <div className="project-add">
            <span onClick={handleCloseFormBrand}>Đóng</span>
          </div>
        </>
      ) : null}
      {formInfo ? (
        <>
          <FormInfo
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
            info={about}
          />
          <div className="project-add">
            <span onClick={handleCloseFormInfo}>Đóng</span>
          </div>
        </>
      ) : null}
      {formImage ? (
        <>
          <FormImage
            image={image}
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormImage}>Đóng</span>
          </div>
        </>
      ) : null}
      <h2>Danh sách secsion</h2>
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Banner</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormBanner}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Infomation</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormInfo}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Image</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormImage}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Danh sách what i do</h2>
      {formWid ? (
        <>
          <FormTitleWID
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
            wid={about}
          />
          <div className="project-add">
            <span onClick={handleCloseFormWid}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3 dangerouslySetInnerHTML={{ __html: about.wid }} />
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormWid}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {formEditWid ? (
        <>
          <FormEditWID
            widItem={wid}
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
          />
          <div className="project-add">
            <span
              onClick={() => {
                setFormEditWid(false);
              }}>
              Đóng
            </span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên Workplace</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {widList.map((wid) => (
            <tr>
              <td>
                <h3 dangerouslySetInnerHTML={{ __html: wid.workplace }} />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => {
                    setWid(wid);
                    setFormEditWid(true);
                  }}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteWid(wid)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formAddWid ? (
        <>
          <FormAddWID onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormAddWid}>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-add">
        <span onClick={handleOpenFormAddWid}>Thêm mới</span>
      </div>
      <h2>Danh sách what i learn</h2>
      {formWil ? (
        <>
          <FormTitleWIL
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
            wil={about}
          />
          <div className="project-add">
            <span onClick={handleCloseFormWil}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3 dangerouslySetInnerHTML={{ __html: about.wil }} />
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormWil}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {formEditWil ? (
        <>
          <FormEditWIL
            wilItem={wil}
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
          />
          <div className="project-add">
            <span
              onClick={() => {
                setFormEditWil(false);
              }}>
              Đóng
            </span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {wilList.map((wil) => (
            <tr>
              <td>
                <h3 dangerouslySetInnerHTML={{ __html: wil.content }} />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => {
                    setWil(wil);
                    setFormEditWil(true);
                  }}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteWil(wil)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formAddWil ? (
        <>
          <FormAddWIL onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormAddWil}>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-add">
        <span onClick={handleOpenFormAddWil}>Thêm mới</span>
      </div>
      <h2>Danh sách what i share</h2>
      {formWis ? (
        <>
          <FormTitleWIS
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
            wis={about}
          />
          <div className="project-add">
            <span onClick={handleCloseFormWis}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3 dangerouslySetInnerHTML={{ __html: about.wis }} />
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormWis}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {formEditWis ? (
        <>
          <FormEditWIS
            wisItem={wis}
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
          />
          <div className="project-add">
            <span
              onClick={() => {
                setFormEditWis(false);
              }}>
              Đóng
            </span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên</th>
            <th>Avatar</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {wisList.map((wis) => (
            <tr>
              <td>
                <h3 dangerouslySetInnerHTML={{ __html: wis.name }} />
              </td>
              <td>
                {checkURL(wis.image) ? (
                  <img
                    src={wis.image}
                    title="Project Image"
                    alt="ProjectImage"
                    className="project-image"
                  />
                ) : isValidYoutubeEmbedUrl(wis.image) ? (
                  <iframe
                    src={wis.image}
                    title="Project Video"
                    alt="ProjectImage"
                    className="project-image"
                  />
                ) : (
                  <iframe
                    src={wis.image}
                    title="Project Video"
                    alt="ProjectImage"
                    className="project-image"
                  />
                )}
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => {
                    setWis(wis);
                    setFormEditWis(true);
                  }}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteWis(wis)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formAddWis ? (
        <>
          <FormAddWIS onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormAddWis}>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-add">
        <span onClick={handleOpenFormAddWis}>Thêm mới</span>
      </div>
      <h2>Danh sách who inspire me</h2>
      {formWim ? (
        <>
          <FormWIM
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
            wim={about}
          />
          <div className="project-add">
            <span onClick={handleCloseFormWim}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3 dangerouslySetInnerHTML={{ __html: about.wim }} />
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormWim}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {formEditWim ? (
        <>
          <FormEditWIM
            wimItem={wim}
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
          />
          <div className="project-add">
            <span onClick={() => setFormEditWim(false)}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Hình ảnh</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {wimList.map((wim) => (
            <tr>
              <td>
                {checkURL(wim.image) ? (
                  <img
                    src={wim.image}
                    title="Project Image"
                    alt="ProjectImage"
                    className="project-image"
                  />
                ) : isValidYoutubeEmbedUrl(wis.image) ? (
                  <iframe
                    src={wim.image}
                    title="Project Video"
                    alt="ProjectImage"
                    className="project-image"
                  />
                ) : (
                  <iframe
                    src={wim.image}
                    title="Project Video"
                    alt="ProjectImage"
                    className="project-image"
                  />
                )}
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => {
                    setWim(wim);
                    setFormEditWim(true);
                  }}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteWim(wim)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formAddWim ? (
        <>
          <FormAddWIM onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormAddWim}>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-add">
        <span onClick={handleOpenFormAddWim}>Thêm mới</span>
      </div>
    </div>
  );
};

export default About;
