import React, { useCallback, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../../server/uploads.js';
import cloudinaryDelete from '../../../../server/delete.js';
import * as yup from 'yup';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import quillEmoji from 'react-quill-emoji';
import 'react-quill-emoji/dist/quill-emoji.css';
import { SnowTheme } from 'quill-color-picker-enhance';
import 'quill-color-picker-enhance/dist/index.css';

Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
  },
  true
);
let Parchment = Quill.import('parchment');
let LineHeightAttributor = new Parchment.Attributor.Style(
  'lineHeight', // Sử dụng camelCase cho tên định dạng
  'line-height',
  {
    scope: Parchment.Scope.BLOCK_ATTRIBUTE,
  }
);
Quill.register(
  {
    'formats/lineHeight': LineHeightAttributor,
  },
  true
);
var Size = Quill.import('attributors/style/size');
Size.whitelist = Array.from(
  { length: 100 },
  (_, i) => (i + 1).toString() + 'px'
);
Quill.register(Size, true);

const schema = yup.object({
  heading: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự'),
  thumbnail: yup.string().required('Không được bỏ trống'),
  id_thumbnail: yup.string().required('Không được bỏ trống'),
});

const FormAddBlog = ({ onRefresh }) => {
  const [listCate, setListCate] = useState([]);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedImagePublicId, setUploadedImagePublicId] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [uploadOption, setUploadOption] = useState('file');
  const [isLoaded, setIsLoaded] = useState(false);

  const reactQuillRef = useRef(null);
  const reactQuillRefContent = useRef(null);
  useEffect(() => {
    let sizePickerItems = document.querySelectorAll('.ql-size .ql-picker-item');
    let lHeightPickerItems = document.querySelectorAll(
      '.ql-lineHeight .ql-picker-item'
    );

    for (let i = 0; i < sizePickerItems.length; i++) {
      let item = sizePickerItems[i];
      let value = item.getAttribute('data-value');
      if (value) {
        item.textContent = value;
      }
    }
    for (let i = 0; i < lHeightPickerItems.length; i++) {
      let item = lHeightPickerItems[i];
      let value = item.getAttribute('data-value');
      if (value) {
        item.textContent = value;
      }
    }
  }, []);

  const form = useForm({
    defaultValues: {
      heading: '',
      thumbnail: '',
      id_thumbnail: '',
      content: '',
      status: 0,
      description: '',
      keyword: '',
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const imageHandlerContent = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const url = await handleFileUploadContent(file);
        const quill = reactQuillRefContent.current;
        if (quill) {
          quill.focus(); // Ensure the editor is focused
          setTimeout(() => {
            const range = quill.getEditorSelection();
            if (range) {
              quill.getEditor().insertEmbed(range.index, 'image', url);
            } else {
              // If there is no selection, insert the image at the end
              const length = quill.getEditor().getLength();
              quill.getEditor().insertEmbed(length - 1, 'image', url);
            }
          }, 0); // Delay to ensure the focus is set
        }
      }
    };
  }, []);

  const handleFileUploadContent = async (file) => {
    const fileData = new FormData();
    fileData.append('file', file);

    try {
      const res = await cloudinaryUpload(fileData);
      const url = res.secure_url;
      return url;
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleFileUpload = async (e) => {
    setIsLoaded(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageUrl(res.secure_url);
      setUploadedImagePublicId(res.publicId);
      setValue('thumbnail', res.secure_url);
      setValue('id_thumbnail', res.publicId);
      setIsImageUploaded(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleDeleteImage = async () => {
    if (!uploadedImagePublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImagePublicId);
      setUploadedImageUrl('');
      setUploadedImagePublicId('');
      setIsImageUploaded(false);
      setValue('thumbnail', '');
      setIsLoaded(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleUploadOptionChange = (e) => {
    setUploadOption(e.target.value);
  };
  const extractSrcFromIframe = (iframeString) => {
    const srcMatch = iframeString.match(
      /src\s*=\s*"([^"]+)"|src\s*=\s*'([^']+)'/
    );
    return srcMatch ? srcMatch[1] || srcMatch[2] : null;
  };

  const handleInputChange = (e) => {
    const embedCode = e.target.value;
    const url = extractSrcFromIframe(embedCode);
    setValue('thumbnail', url);
  };
  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };

  const handleSubmitProject = async (data) => {
    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        function (char) {
          return '&#x' + char.codePointAt(0).toString(16) + ';';
        }
      );
    }
    function convertObjectToUnicode(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]); // Nếu phần tử là một đối tượng, gọi đệ quy
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]); // Nếu phần tử là một chuỗi, chuyển đổi thành Unicode
        }
      }
      return obj;
    }

    if (data.time) {
      data.time = formatDateToMySQL(data.time);
    }

    const convertedData = convertObjectToUnicode(data);
    try {
      const url = 'https://api.thechuck.vn/blog/add-blog';
      const opt = {
        method: 'post',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi thêm sản phẩm: ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      onRefresh();
    }
    const fetchProjectTag = async () => {
      const res = await fetch(`https://api.thechuck.vn/blog/get-cate-blog`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setListCate(data);
    };
    fetchProjectTag();
  }, [isSubmitSuccessful, reset, onRefresh]);

  function formatDateToMySQL(isoString) {
    const date = new Date(isoString);
    // Chuyển đổi thời gian thành múi giờ Asia/Ho_Chi_Minh
    const options = {
      timeZone: 'Asia/Ho_Chi_Minh',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    // Định dạng thời gian thành một chuỗi theo múi giờ Asia/Ho_Chi_Minh
    const localDateString = date
      .toLocaleString('en-GB', options)
      .replace(/,/, '');

    // Chuyển đổi chuỗi địa phương thành định dạng MySQL
    const [day, month, year, hour, minute, second] =
      localDateString.match(/\d+/g);
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  const options = listCate?.map((tag) => ({
    value: tag.cate_post_id,
    label: stripHtml(tag.name),
  }));

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <label>Keyword</label>
          <textarea
            placeholder="từ khóa chính, từ khóa phụ, ..."
            {...register('keyword')}
            rows={4}
          />
        </div>
        <div className="form-project-item">
          <label>Mổ tả ngắn</label>
          <textarea
            placeholder="Mô tả trang chứa từ khóa chính của bạn"
            {...register('description')}
            rows={5}
          />
        </div>
        <div className="form-project-item">
          <label>Tiêu đề bài viết</label>
          <ReactQuill
            ref={reactQuillRef}
            theme="snow-quill-color-picker-enhance"
            value={form.watch('heading')}
            onChange={(content) => form.setValue('heading', content)}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [
                    {
                      lineHeight: [
                        '1',
                        '1.1',
                        '1.2',
                        '1.3',
                        '1.4',
                        '1.5',
                        '1.6',
                        '1.7',
                        '1.8',
                        '1.9',
                        '2',
                        '2.5',
                        '3',
                      ],
                    },
                  ], // Đảm bảo cú pháp này chính xác
                  [{ size: Size.whitelist }],
                  [{ header: [2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ['clean'],
                  ['link'],
                  ['emoji'],
                ],
                'emoji-toolbar': true,
              },
              'emoji-textarea': true,
              'emoji-shortname': true,
              clipboard: {
                matchVisual: false,
              },
            }}
          />
          {errors.heading && <p className="error">{errors.heading.message}</p>}
        </div>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Hình Thumbnail</label>
              <select onChange={(e) => handleUploadOptionChange(e)}>
                <option value="file">Tải lên từ máy</option>
                <option value="url">Nhập link từ YouTube</option>
              </select>
              {uploadOption === 'file' ? (
                <input
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                  accept="image/*,video/*"
                />
              ) : (
                <input
                  type="text"
                  placeholder="Nhập mã nhúng từ YouTube"
                  onChange={handleInputChange}
                />
              )}
              {errors.thumbnail && (
                <p className="error">{errors.thumbnail.message}</p>
              )}
            </div>
            <div className="form-project-flex-item">
              {/* Hiển thị hình ảnh đã tải lên */}
              {isLoaded ? (
                isImageUploaded && isImageUploaded ? (
                  <div className="form-project-flex-img">
                    {checkURL(uploadedImageUrl) ? (
                      <img
                        src={uploadedImageUrl}
                        title="Project Image"
                        alt="Uploaded"
                      />
                    ) : (
                      <iframe
                        src={uploadedImageUrl}
                        title="Project Video"
                        alt="Uploaded"
                      />
                    )}
                    <p onClick={handleDeleteImage}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <label>Danh mục</label>
          <Controller
            name="listCate"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Select
                {...field}
                isMulti
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) => field.onChange(selectedOptions)}
              />
            )}
          />
        </div>
        <div className="form-project-item">
          <label>Nội dung</label>
          <ReactQuill
            ref={reactQuillRefContent}
            theme="snow-quill-color-picker-enhance"
            value={form.watch('content')}
            onChange={(content) => form.setValue('content', content)}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [
                    {
                      lineHeight: [
                        '1',
                        '1.1',
                        '1.2',
                        '1.3',
                        '1.4',
                        '1.5',
                        '1.6',
                        '1.7',
                        '1.8',
                        '1.9',
                        '2',
                        '2.5',
                        '3',
                      ],
                    },
                  ], // Đảm bảo cú pháp này chính xác
                  [{ size: Size.whitelist }],
                  [{ header: [2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ['clean'],
                  ['link'],
                  ['video'],
                  ['image', 'emoji'],
                ],
                'emoji-toolbar': true,
                handlers: {
                  image: imageHandlerContent,
                },
              },
              'emoji-textarea': true,
              'emoji-shortname': true,
              clipboard: {
                matchVisual: false,
              },
            }}
          />
        </div>
        <div className="form-project-item">
          <label>Trạng thái</label>
          <select {...register('status', { valueAsNumber: true })}>
            <option value="0">Ẩn</option>
            <option value="1">Hoàn thành</option>
          </select>
          {errors.status && <p className="error">{errors.status.message}</p>}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
        <DevTool control={control} />
      </form>
    </div>
  );
};

export default FormAddBlog;
