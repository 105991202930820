import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import './App.css';
import ScrollToTop from './ScrollToTop/ScrollToTop';
import Loading from './Loading/Loading';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Load from './Loading/Load';

const Homepage = lazy(() => import('./Home/Homepage'));
const WorkDetail = lazy(() => import('./WorkDetail/WorkDetail'));
const Blog = lazy(() => import('./Blog/Blog'));
const BlogDetail = lazy(() => import('./BlogDetail/BlogDetail'));
const Expertise = lazy(() => import('./Expertise/Expertise'));
const Thoughts = lazy(() => import('./Thoughts/Thoughts'));
const About = lazy(() => import('./About/About'));

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 1.5,
};

const App = ({ headerMeta }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [menu, setMenu] = useState({});
  const location = useLocation();

  useEffect(() => {
    const fetchHeaderMenu = async () => {
      try {
        const res = await fetch('https://api.thechuck.vn/header/get-menu');
        const data = await res.json();
        setMenu(data);
      } catch (error) {
        console.error('Error fetching menu:', error);
      } finally {
        setInitialLoading(false);
      }
    };

    fetchHeaderMenu();
  }, []);

  if (initialLoading) {
    return <Load />;
  }

  return (
    <AnimatePresence mode="wait">
      <HelmetProvider>
        <Loading />
        <div className="wrapper">
          <Helmet>
            <meta
              name="description"
              content={headerMeta?.description || 'Default Description'}
            />
          </Helmet>
          <Header
            menu={menu}
            headerMeta={headerMeta}
          />
          <ScrollToTop />
          <main className="main">
            <Suspense fallback={[]}>
              <Routes
                location={location}
                key={location.pathname}>
                <Route
                  path="/"
                  element={
                    <AnimatedPage>
                      <Homepage menu={menu} />
                    </AnimatedPage>
                  }
                />
                <Route
                  path={`/${menu.item_1?.toLowerCase()}`}
                  element={
                    <AnimatedPage>
                      <Homepage menu={menu} />
                    </AnimatedPage>
                  }
                />
                <Route
                  path={`/${menu.item_1?.toLowerCase()}/:slug`}
                  element={
                    <AnimatedPage>
                      <WorkDetail menu={menu} />
                    </AnimatedPage>
                  }
                />
                <Route
                  path={`/${menu.item_2?.toLowerCase()}`}
                  element={
                    <AnimatedPage>
                      <Blog menu={menu} />
                    </AnimatedPage>
                  }
                />
                <Route
                  path={`/${menu.item_2?.toLowerCase()}/:slug`}
                  element={
                    <AnimatedPage>
                      <BlogDetail menu={menu} />
                    </AnimatedPage>
                  }
                />
                <Route
                  path={`/${menu.item_4?.toLowerCase()}`}
                  element={
                    <AnimatedPage>
                      <Expertise />
                    </AnimatedPage>
                  }
                />
                <Route
                  path={`/${menu.item_5?.toLowerCase()}`}
                  element={
                    <AnimatedPage>
                      <Thoughts />
                    </AnimatedPage>
                  }
                />
                <Route
                  path={`/${menu.item_3?.toLowerCase()}`}
                  element={
                    <AnimatedPage>
                      <About />
                    </AnimatedPage>
                  }
                />
              </Routes>
            </Suspense>
          </main>
          <Footer />
        </div>
      </HelmetProvider>
    </AnimatePresence>
  );
};

const AnimatedPage = ({ children }) => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}>
      {children}
    </motion.div>
  );
};

export default App;
