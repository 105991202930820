import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../../server/uploads.js';
import cloudinaryDelete from '../../../../server/delete.js';
import * as yup from 'yup';

const schema = yup.object({
  image_left: yup.string().required('Không được bỏ trống'),
  image_right: yup.string().required('Không được bỏ trống'),
});

const FormThoughtBanne = ({ onRefresh, thoughtBanner }) => {
  const [uploadedImageLeft, setUploadedImageLeft] = useState('');
  const [uploadedImageLeftPublicId, setUploadedImageLeftPublicId] =
    useState('');
  const [isImageLeftUploaded, setIsImageLeftUploaded] = useState(false);
  const [uploadOptionLeft, setUploadOptionLeft] = useState('file');
  const [isLeftLoaded, setIsLeftLoaded] = useState(false);
  const [uploadedImageRight, setUploadedImageRight] = useState('');
  const [uploadedImageRightPublicId, setUploadedImageRightPublicId] =
    useState('');
  const [isImageRightUploaded, setIsImageRightUploaded] = useState(false);
  const [uploadOptionRight, setUploadOptionRight] = useState('file');
  const [isRightLoaded, setIsRightLoaded] = useState(false);

  const form = useForm({
    defaultValues: {
      image_left: thoughtBanner.image_left,
      id_image_left: thoughtBanner.id_image_left,
      image_right: thoughtBanner.image_right,
      id_image_right: thoughtBanner.id_image_right,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const fetchBanner = useCallback(async () => {
    try {
      const res = await fetch(`https://api.thechuck.vn/thought/get-banner`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setValue('image_left', data.image_left);
      setValue('id_image_left', data.id_image_left);
      setValue('image_right', data.image_right);
      setValue('id_image_right', data.id_image_right);
    } catch (error) {
      console.error('Error fetching project tag details:', error);
    }
  }, [setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onRefresh();
      fetchBanner();
      reset({ ...thoughtBanner });
    }
  }, [isSubmitSuccessful, fetchBanner, onRefresh, reset, thoughtBanner]);

  const handleFileUploadImageLeft = async (e) => {
    setIsLeftLoaded(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageLeft(res.secure_url);
      setUploadedImageLeftPublicId(res.publicId);
      setValue('image_left', res.secure_url);
      setValue('id_image_left', res.publicId);
      setIsImageLeftUploaded(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleDeleteImageLeft = async () => {
    if (!setUploadedImageLeftPublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImageLeftPublicId);
      setUploadedImageLeft('');
      setUploadedImageLeftPublicId('');
      setIsImageLeftUploaded(false);
      setValue('image_left', '');
      setIsLeftLoaded(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleUploadOptionLeftChange = (e) => {
    setUploadOptionLeft(e.target.value);
  };

  const handleInputChangeLeft = (e) => {
    const embedCode = e.target.value;
    const url = extractSrcFromIframe(embedCode);
    setValue('image_left', url);
  };

  const handleFileUploadImageRight = async (e) => {
    setIsRightLoaded(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageRight(res.secure_url);
      setUploadedImageRightPublicId(res.publicId);
      setValue('image_right', res.secure_url);
      setValue('id_image_right', res.publicId);
      setIsImageRightUploaded(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleDeleteImageRight = async () => {
    if (!setUploadedImageRightPublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImageRightPublicId);
      setUploadedImageRight('');
      setUploadedImageRightPublicId('');
      setIsImageRightUploaded(false);
      setValue('image_right', '');
      setIsRightLoaded(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleUploadOptionRightChange = (e) => {
    setUploadOptionRight(e.target.value);
  };

  const handleInputChangeRight = (e) => {
    const embedCode = e.target.value;
    const url = extractSrcFromIframe(embedCode);
    setValue('image_right', url);
  };

  const extractSrcFromIframe = (iframeString) => {
    const srcMatch = iframeString.match(
      /src\s*=\s*"([^"]+)"|src\s*=\s*'([^']+)'/
    );
    return srcMatch ? srcMatch[1] || srcMatch[2] : null;
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const handleSubmitProject = async (data) => {
    if (isImageLeftUploaded) {
      cloudinaryDelete(thoughtBanner.id_image_left);
    }
    if (isImageRightUploaded) {
      cloudinaryDelete(thoughtBanner.id_image_right);
    }
    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        function (char) {
          return '&#x' + char.codePointAt(0).toString(16) + ';';
        }
      );
    }
    function convertObjectToUnicode(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]); // Nếu phần tử là một đối tượng, gọi đệ quy
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]); // Nếu phần tử là một chuỗi, chuyển đổi thành Unicode
        }
      }
      return obj;
    }

    const convertedData = convertObjectToUnicode(data);
    try {
      const url = `https://api.thechuck.vn/thought/update-banner`;
      const opt = {
        method: 'put',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi thêm sản phẩm: ', error);
    }
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Hình Trái</label>
              <select onChange={(e) => handleUploadOptionLeftChange(e)}>
                <option value="file">Tải lên từ máy</option>
                <option value="url">Nhập link từ YouTube</option>
              </select>
              {uploadOptionLeft === 'file' ? (
                <input
                  type="file"
                  onChange={(e) => handleFileUploadImageLeft(e)}
                  accept="image/*,video/*"
                />
              ) : (
                <input
                  type="text"
                  placeholder="Nhập mã nhúng từ YouTube"
                  onChange={handleInputChangeLeft}
                />
              )}
              {errors.image_left && (
                <p className="error">{errors.image_left.message}</p>
              )}
            </div>
            <div className="form-project-flex-item">
              {/* Hiển thị hình ảnh đã tải lên */}
              {isLeftLoaded ? (
                isImageLeftUploaded && isImageLeftUploaded ? (
                  <div className="form-project-flex-img">
                    {checkURL(uploadedImageLeft) ? (
                      <img
                        src={uploadedImageLeft}
                        title="Project Image"
                        alt="Project_Image"
                      />
                    ) : (
                      <iframe
                        src={uploadedImageLeft}
                        title="Project Video"
                        alt="Project_Video"
                      />
                    )}
                    <p onClick={handleDeleteImageLeft}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : (
                <div className="form-project-flex-img">
                  {checkURL(thoughtBanner.image_left) ? (
                    <img
                      src={thoughtBanner.image_left}
                      title="Project Image"
                      alt="Project_Image"
                    />
                  ) : isValidYoutubeEmbedUrl(thoughtBanner.image_left) ? (
                    <iframe
                      src={thoughtBanner.image_left}
                      title="Project Video"
                      alt="Project_Video"
                    />
                  ) : (
                    <iframe
                      src={thoughtBanner.image_left}
                      title="Project Video"
                      alt="Project_Video"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Hình Phải</label>
              <select onChange={(e) => handleUploadOptionRightChange(e)}>
                <option value="file">Tải lên từ máy</option>
                <option value="url">Nhập link từ YouTube</option>
              </select>
              {uploadOptionRight === 'file' ? (
                <input
                  type="file"
                  onChange={(e) => handleFileUploadImageRight(e)}
                  accept="image/*,video/*"
                />
              ) : (
                <input
                  type="text"
                  placeholder="Nhập mã nhúng từ YouTube"
                  onChange={handleInputChangeRight}
                />
              )}
              {errors.image_right && (
                <p className="error">{errors.image_right.message}</p>
              )}
            </div>
            <div className="form-project-flex-item">
              {/* Hiển thị hình ảnh đã tải lên */}
              {isRightLoaded ? (
                isImageRightUploaded && isImageRightUploaded ? (
                  <div className="form-project-flex-img">
                    {checkURL(uploadedImageRight) ? (
                      <img
                        src={uploadedImageRight}
                        title="Project Image"
                        alt="Project_Image"
                      />
                    ) : (
                      <iframe
                        src={uploadedImageRight}
                        title="Project Video"
                        alt="Project_Video"
                      />
                    )}
                    <p onClick={handleDeleteImageRight}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : (
                <div className="form-project-flex-img">
                  {checkURL(thoughtBanner.image_right) ? (
                    <img
                      src={thoughtBanner.image_right}
                      title="Project Image"
                      alt="Project_Image"
                    />
                  ) : isValidYoutubeEmbedUrl(thoughtBanner.image_right) ? (
                    <iframe
                      src={thoughtBanner.image_right}
                      title="Project Video"
                      alt="Project_Video"
                    />
                  ) : (
                    <iframe
                      src={thoughtBanner.image_right}
                      title="Project Video"
                      alt="Project_Video"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormThoughtBanne;
