import React, { useEffect, useState } from 'react';
import FormLogo from './FormHeader/FormLogo';
import FormIcon from './FormHeader/FormIcon';
import FormInfo from './FormHeader/FormInfo';
import FormMenu from './FormHeader/FormMenu';
import FormSocial from './FormHeader/FormSocial';
import FormText from './FormHeader/FormText';
import FormDesc from './FormHeader/FormDesc';

const Header = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [headerMeta, setHeaderMeta] = useState({});
  const [formLogo, setFormLogo] = useState(false);
  const [formIcon, setFormIcon] = useState(false);
  const [formDesc, setFormDesc] = useState(false);
  const [headerInfo, setHeaderInfo] = useState({});
  const [formInfo, setFormInfo] = useState(false);
  const [menu, setMenu] = useState({});
  const [formMenu, setFormMenu] = useState(false);
  const [social, setSocial] = useState({});
  const [formSocial, setFormSocial] = useState(false);
  const [text, setText] = useState({});
  const [formText, setFormText] = useState(false);

  const handleOpenFormLogo = () => {
    setFormLogo(true);
    setFormIcon(false);
    setFormInfo(false);
    setFormMenu(false);
    setFormSocial(false);
    setFormText(false);
    setFormDesc(false);
  };
  const handleCloseFormLogo = () => {
    setFormLogo(false);
  };

  const handleOpenFormIcon = () => {
    setFormIcon(true);
    setFormLogo(false);
    setFormInfo(false);
    setFormMenu(false);
    setFormSocial(false);
    setFormText(false);
    setFormDesc(false);
  };
  const handleCloseFormIcon = () => {
    setFormIcon(false);
  };

  const handleOpenFormInfo = () => {
    setFormInfo(true);
    setFormLogo(false);
    setFormIcon(false);
    setFormMenu(false);
    setFormSocial(false);
    setFormText(false);
    setFormDesc(false);
  };
  const handleCloseFormInfo = () => {
    setFormInfo(false);
  };

  const handleOpenFormMenu = () => {
    setFormMenu(true);
    setFormLogo(false);
    setFormInfo(false);
    setFormIcon(false);
    setFormSocial(false);
    setFormText(false);
    setFormDesc(false);
  };
  const handleCloseFormMenu = () => {
    setFormMenu(false);
  };

  const handleOpenFormSocial = () => {
    setFormSocial(true);
    setFormLogo(false);
    setFormInfo(false);
    setFormIcon(false);
    setFormMenu(false);
    setFormText(false);
    setFormDesc(false);
  };
  const handleCloseFormSocial = () => {
    setFormSocial(false);
  };
  const handleOpenFormText = () => {
    setFormText(true);
    setFormLogo(false);
    setFormInfo(false);
    setFormIcon(false);
    setFormMenu(false);
    setFormSocial(false);
    setFormDesc(false);
  };
  const handleCloseFormText = () => {
    setFormText(false);
  };

  const handleOpenFormDessc = () => {
    setFormDesc(true);
    setFormLogo(false);
    setFormInfo(false);
    setFormIcon(false);
    setFormMenu(false);
    setFormSocial(false);
    setFormText(false);
  };
  const handleCloseFormDesc = () => {
    setFormDesc(false);
  };

  useEffect(() => {
    const fetchHeaderMeta = async () => {
      const res = await fetch('https://api.thechuck.vn/header/get-meta');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setHeaderMeta(data);
    };
    const fetchHeaderInfo = async () => {
      const res = await fetch('https://api.thechuck.vn/header/get-info');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setHeaderInfo(data);
    };
    const fetchHeaderMenu = async () => {
      const res = await fetch('https://api.thechuck.vn/header/get-menu');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setMenu(data);
    };
    const fetchHeaderSocial = async () => {
      const res = await fetch('https://api.thechuck.vn/header/get-social');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setSocial(data);
    };
    const fetchHeaderText = async () => {
      const res = await fetch('https://api.thechuck.vn/header/get-text');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setText(data);
    };

    fetchHeaderInfo();
    fetchHeaderMeta();
    fetchHeaderMenu();
    fetchHeaderSocial();
    fetchHeaderText();
  }, [refreshKey]);

  return (
    <div class="recent-orders">
      <h1>Header</h1>
      {formLogo ? (
        <>
          <FormLogo
            onRefresh={() => setRefreshKey((key) => key + 1)}
            logo={headerMeta}
          />
          <div className="project-add">
            <span onClick={handleCloseFormLogo}>Đóng</span>
          </div>
        </>
      ) : null}
      {formIcon ? (
        <>
          <FormIcon
            onRefresh={() => setRefreshKey((key) => key + 1)}
            icon={headerMeta}
          />
          <div className="project-add">
            <span onClick={handleCloseFormIcon}>Đóng</span>
          </div>
        </>
      ) : null}
      {formInfo ? (
        <>
          <FormInfo
            onRefresh={() => setRefreshKey((key) => key + 1)}
            info={headerInfo}
          />
          <div className="project-add">
            <span onClick={handleCloseFormInfo}>Đóng</span>
          </div>
        </>
      ) : null}
      {formMenu ? (
        <>
          <FormMenu
            onRefresh={() => setRefreshKey((key) => key + 1)}
            menu={menu}
          />
          <div className="project-add">
            <span onClick={handleCloseFormMenu}>Đóng</span>
          </div>
        </>
      ) : null}
      {formSocial ? (
        <>
          <FormSocial
            onRefresh={() => setRefreshKey((key) => key + 1)}
            item={social}
          />
          <div className="project-add">
            <span onClick={handleCloseFormSocial}>Đóng</span>
          </div>
        </>
      ) : null}
      {formText ? (
        <>
          <FormText
            onRefresh={() => setRefreshKey((key) => key + 1)}
            text={text}
          />
          <div className="project-add">
            <span onClick={handleCloseFormText}>Đóng</span>
          </div>
        </>
      ) : null}
      {formDesc ? (
        <>
          <FormDesc
            item={headerMeta}
            onRefresh={() => setRefreshKey((key) => key + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormDesc}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Text</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormText}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Logo</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormLogo}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Icon</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormIcon}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Infomation</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormInfo}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Menu</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormMenu}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Social</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormSocial}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Mô tả SEO</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormDessc}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Header;
