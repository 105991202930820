import React, { useEffect, useState } from 'react';
import FormAddTag from './FormTag/FormAddTag';
import FormEditTag from './FormTag/FormEditTag';

const ProjectTag = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [workTags, setWorkTags] = useState([]);
  const [openFormTag, setOpenFormTag] = useState(false);
  const [openFormEditTag, setOpenFormEditTag] = useState(false);
  const [tag, setTag] = useState({});

  const handleOpenFormTag = () => {
    setOpenFormTag(true);
  };
  const handleCloseFormTag = () => {
    setOpenFormTag(false);
  };
  const handleOpenFormEditTag = (tag) => {
    setOpenFormEditTag(true);
    setTag(tag);
  };
  const handleCloseFormEditTag = () => {
    setOpenFormEditTag(false);
  };

  useEffect(() => {
    const fetchProjectTag = async () => {
      const res = await fetch(`https://api.thechuck.vn/projects/get-tag`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setWorkTags(data);
    };
    fetchProjectTag();
    setOpenFormTag(false);
    setOpenFormEditTag(false);
  }, [refreshKey]);

  const deleteProject = (tag) => {
    if (window.confirm('Bạn muốn xóa tag này không?')) {
      fetch(`https://api.thechuck.vn/projects/delete-tag/${tag.ptag_id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch('https://api.thechuck.vn/projects/get-tag');
            const data = await res.json();
            setWorkTags(data);
          };
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div class="recent-orders">
      <h1>Tag dự án</h1>
      {openFormEditTag ? (
        <>
          <FormEditTag
            tag={tag}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditTag}>Đóng</span>
          </div>
        </>
      ) : null}
      <h2>Danh sách tag</h2>
      <table>
        <thead>
          <tr>
            <th>Tên tag</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {workTags.map((tag, index) => (
            <tr key={index}>
              <td>
                <p dangerouslySetInnerHTML={{ __html: tag.name }} />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditTag(tag)}>
                  Sửa
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteProject(tag)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="project-add">
        <span onClick={handleOpenFormTag}>Thêm tag mới</span>
      </div>
      {openFormTag ? (
        <>
          <FormAddTag onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormTag}>Đóng</span>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ProjectTag;
