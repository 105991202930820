import React, { useEffect, useState } from 'react';
import FormAddExper from './FormExper/FormAddExper';
import FormEditExper from './FormExper/FormEditExper';
import FormAddBrand from './FormBrand/FormAddBrand';
import FormEditBrand from './FormBrand/FormEditBrand';
import FormImage from './FormSecsion/FormImage';
import FormInfo from './FormSecsion/FormInfo';

const Expertise = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [expertise, setExpertise] = useState([]);
  const [brand, setBrand] = useState([]);
  const [exper, setExper] = useState({});
  const [brandItem, setBrandItem] = useState({});
  const [experImage, setExperImage] = useState({});
  const [experInfo, setExperInfo] = useState({});
  const [formExpertise, setFormExpertise] = useState(false);
  const [formEditExpertise, setFormEditExpertise] = useState(false);
  const [formBrand, setFormBrand] = useState(false);
  const [formEditBrand, setFormEditBrand] = useState(false);
  const [formImage, setFormImage] = useState(false);
  const [formInfo, setFormInfo] = useState(false);

  const handleOpenFormExpertise = (item) => {
    setFormExpertise(true);
  };
  const handleCloseFormExpertise = () => {
    setFormExpertise(false);
  };
  const handleOpenFormEditExpertise = (item) => {
    setExper(item);
    setFormEditExpertise(true);
  };
  const handleCloseFormEditExpertise = () => {
    setFormEditExpertise(false);
  };

  const handleOpenFormBrand = () => {
    setFormBrand(true);
  };
  const handleCloseFormBrand = () => {
    setFormBrand(false);
  };
  const handleOpenFormEditBrand = (item) => {
    setBrandItem(item);
    setFormEditBrand(true);
  };
  const handleCloseFormEditBrand = () => {
    setFormEditBrand(false);
  };

  const handleOpenFormImage = () => {
    setFormImage(true);
    setFormInfo(false);
  };
  const handleCloseFormImage = () => {
    setFormImage(false);
  };
  const handleOpenFormInfo = () => {
    setFormInfo(true);
    setFormImage(false);
  };
  const handleCloseFormInfo = () => {
    setFormInfo(false);
  };

  useEffect(() => {
    const fetchExpertise = async () => {
      const res = await fetch(
        'https://api.thechuck.vn/expertise/get-expertise'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setExpertise(data);
    };
    const fetchBrand = async () => {
      const res = await fetch(
        'https://api.thechuck.vn/expertise/get-expertise-brand'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBrand(data);
    };
    const fetchImage = async () => {
      const res = await fetch('https://api.thechuck.vn/expertise/get-image');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setExperImage(data);
    };
    const fetchInfo = async () => {
      const res = await fetch('https://api.thechuck.vn/expertise/get-info');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setExperInfo(data);
    };

    fetchBrand();
    fetchExpertise();
    fetchImage();
    fetchInfo();
    setFormExpertise(false);
    setFormBrand(false);
  }, [refreshKey]);

  const deleteExpertise = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(
        `https://api.thechuck.vn/expertise/delete-expertise/${item.expertise_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchExpertise = async () => {
            const res = await fetch(
              'https://api.thechuck.vn/expertise/get-expertise'
            );
            const text = await res.text();
            const data = text ? JSON.parse(text) : {};
            setExpertise(data);
          };

          fetchExpertise();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const deleteBrand = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(
        `https://api.thechuck.vn/expertise/delete-expertise-brand/${item.expertise_brand_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchBrand = async () => {
            const res = await fetch(
              'https://api.thechuck.vn/expertise/get-expertise-brand'
            );
            const text = await res.text();
            const data = text ? JSON.parse(text) : {};
            setBrand(data);
          };

          fetchBrand();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div class="recent-orders">
      <h1>Expertise</h1>
      <h2>Danh sách secsion</h2>
      {formImage ? (
        <>
          <FormImage
            image={experImage}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormImage}>Đóng</span>
          </div>
        </>
      ) : null}
      {formInfo ? (
        <>
          <FormInfo
            info={experInfo}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormInfo}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Image</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormImage}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Infomation</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormInfo}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Danh sách expertise</h2>
      {formEditExpertise ? (
        <>
          <FormEditExper
            exper={exper}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditExpertise}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên tiêu đề</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {expertise.map((item, index) => (
            <tr key={index}>
              <td>
                <h3 dangerouslySetInnerHTML={{ __html: item.heading }} />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditExpertise(item)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteExpertise(item)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formExpertise ? (
        <>
          <FormAddExper onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormExpertise}>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-add">
        <span onClick={handleOpenFormExpertise}>Thêm mới</span>
      </div>
      <h2>Danh sách brand</h2>
      {formEditBrand ? (
        <>
          <FormEditBrand
            brand={brandItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditBrand}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Logo brand</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {brand.map((item, index) => (
            <tr key={index}>
              <td>
                {checkURL(item.logo) ? (
                  <img
                    src={item.logo}
                    title="Project Image"
                    alt="ProjectImage"
                    className="project-image"
                  />
                ) : isValidYoutubeEmbedUrl(item.logo) ? (
                  <iframe
                    src={item.logo}
                    title="Project Video"
                    alt="ProjectImage"
                    className="project-image"
                  />
                ) : (
                  <iframe
                    src={item.logo}
                    title="Project Video"
                    alt="ProjectImage"
                    className="project-image"
                  />
                )}
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditBrand(item)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteBrand(item)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formBrand ? (
        <>
          <FormAddBrand onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormBrand}>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-add">
        <span onClick={handleOpenFormBrand}>Thêm mới</span>
      </div>
    </div>
  );
};

export default Expertise;
