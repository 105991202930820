import React, { useEffect, useState } from 'react';
import './Loading.css';

const Loading = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="loadingPage"
      style={{
        opacity: visible ? 0 : 1,
        visibility: visible ? 'hidden' : 'visible',
      }}>
      <div class="loader"></div>
    </div>
  );
};

export default Loading;
