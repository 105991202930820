import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  project: yup.boolean(),
  collaborations: yup.boolean(),
  something: yup.boolean(),
});

const Header = ({ menu, headerMeta }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isAbout, setIsAbout] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [headerInfo, setHeaderInfo] = useState({});
  const [social, setSocial] = useState({});
  const [text, setText] = useState({});

  useEffect(() => {
    const fetchHeaderData = async () => {
      try {
        const [infoRes, socialRes, textRes] = await Promise.all([
          fetch('https://api.thechuck.vn/header/get-info'),
          fetch('https://api.thechuck.vn/header/get-social'),
          fetch('https://api.thechuck.vn/header/get-text'),
        ]);

        const infoData = infoRes.ok ? await infoRes.json() : {};
        const socialData = socialRes.ok ? await socialRes.json() : {};
        const textData = textRes.ok ? await textRes.json() : {};

        setHeaderInfo(infoData);
        setSocial(socialData);
        setText(textData);
      } catch (error) {
        console.error('Failed to fetch header data:', error);
      }
    };

    fetchHeaderData();
  }, []);

  const updateTime = () => {
    const now = new Date();

    // Convert the current time to Vietnam Time (GMT+7)
    const vietnamTime = new Date(
      now.toLocaleString('en-US', { timeZone: 'Asia/Ho_Chi_Minh' })
    );

    // Extract hours, minutes, and seconds
    const hours = vietnamTime.getHours();
    const minutes = vietnamTime.getMinutes();
    const seconds = vietnamTime.getSeconds();

    // Format hours, minutes, and seconds to two-digit strings
    const strTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    // Format the date
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = days[vietnamTime.getDay()];
    const monthName = months[vietnamTime.getMonth()];
    const day = vietnamTime.getDate();
    const year = vietnamTime.getFullYear();
    const strDate = `${dayName}, ${monthName} ${day}, ${year}`;

    // Update state or DOM elements
    setTime(`Ho Chi Minh City ${strTime}`);
    setDate(strDate);
  };

  useEffect(() => {
    updateTime();
    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const scrollThreshold = window.innerWidth * 0.5069; // Tính toán 50,69% chiều rộng cửa sổ

    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (location.pathname === '/about') {
        if (scrollTop > scrollThreshold) {
          setIsAbout(false);
        } else {
          setIsAbout(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Dọn dẹp sự kiện khi thành phần bị huỷ
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  const isActive = (pathname) => {
    if (pathname === '/work') {
      // Kiểm tra nếu đường dẫn chính xác là '/work' hoặc đường dẫn bắt đầu bằng '/work/'
      return (
        location.pathname === '/work' ||
        location.pathname.startsWith('/work/') ||
        location.pathname === '/'
      );
    }
    // Cho phép kích hoạt đường dẫn chính xác, bao gồm cả đường dẫn con
    return location.pathname.startsWith(pathname);
  };

  useEffect(() => {
    setIsAbout(location.pathname === '/about' ? true : false);
  }, [location.pathname]);

  let lastScrollTop = 0;
  window.addEventListener(
    'scroll',
    function () {
      let currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;
      if (!isOpen) {
        if (currentScrollTop > lastScrollTop) {
          // Người dùng đang scroll xuống, ẩn header
          document.querySelector('.header').classList.add('hide');
        } else {
          // Người dùng đang scroll lên, hiện header
          document.querySelector('.header').classList.remove('hide');
        }
      } else {
        document.querySelector('.header').classList.remove('hide');
      }

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // Đối với các trình duyệt không hỗ trợ pageYOffset
    },
    false
  );

  const toggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    return isOpen;
  };
  const nextPage = () => {
    setIsOpen(false);
    return isOpen;
  };
  const closeModal = () => {
    setIsSubmit(false);
    return isSubmit;
  };

  const handleSubmitProject = async (formData) => {
    const data = { ...formData };

    data.project = formData.project ? 1 : 0;
    data.collaborations = formData.collaborations ? 1 : 0;
    data.something = formData.something ? 1 : 0;

    try {
      const url = 'https://api.thechuck.vn/contact/add';
      const opt = {
        method: 'post',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      console.log(reponseData);
      setIsSubmit(true);
    } catch (error) {
      console.error('Lỗi khi thêm sản phẩm: ', error);
    }
  };

  const form = useForm({
    defaultValues: {
      name: '',
      email: '',
      project: false,
      collaborations: false,
      something: false,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control } = form;
  const { errors, isSubmitSuccessful } = formState;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <>
      <header
        className={`header is-fixed ${isOpen ? 'dark-mode' : ''} ${
          isAbout ? 'about' : ''
        }`}>
        <div className="container-fixed">
          <div className="header-wrapper">
            <p className="header-text column col-6">
              <Link
                to="/"
                onClick={nextPage}
                dangerouslySetInnerHTML={{ __html: text.content }}
              />
            </p>
            <div className="hearder-list column col-6">
              <form className="header-search column col-6">
                <input
                  name="search"
                  placeholder="SEARCH"
                />
              </form>
              <Link
                to="mailto:work.leodang@gmail.com"
                className={`header-contact column col-4`}
                onClick={nextPage}>
                <span className="header-text">LET'S GET TO WORK</span>
                <span className="header-arrow">
                  <i className="fa-solid fa-arrow-right-long"></i>
                </span>
              </Link>
              <button className="header-wrap ">
                <label class="hamburger">
                  <input
                    type="checkbox"
                    checked={isOpen}
                  />
                  <svg
                    viewBox="0 0 32 32"
                    onClick={toggleMenu}>
                    <path
                      class="line line-top-bottom"
                      d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></path>
                    <path
                      class="line"
                      d="M7 16 27 16"></path>
                  </svg>
                </label>
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className={`menu ${isOpen ? 'is-show' : ''}`}>
        <div className="container menu-wrapper">
          <nav className="menu-list column col-4">
            <li>
              <Link
                to={`/${menu.item_1.toLowerCase()}`}
                className={`menu-link ${isActive('/work') ? 'active' : ''}`}
                onClick={nextPage}>
                {menu.item_1}
              </Link>
            </li>
            <li>
              <Link
                to={`/${menu.item_2.toLowerCase()}`}
                className={`menu-link ${isActive('/blog') ? 'active' : ''}`}
                onClick={nextPage}>
                {menu.item_2}
              </Link>
            </li>
            <li>
              <Link
                to={`/${menu.item_3.toLowerCase()}`}
                className={`menu-link ${isActive('/about') ? 'active' : ''}`}
                onClick={nextPage}>
                {menu.item_3}
              </Link>
            </li>
            <li>
              <Link
                to={`/${menu.item_4.toLowerCase()}`}
                className={`menu-link ${
                  isActive('/expertise') ? 'active' : ''
                }`}
                onClick={nextPage}>
                {menu.item_4}
              </Link>
            </li>
            <li>
              <Link
                to={`/${menu.item_5.toLowerCase()}`}
                className={`menu-link ${isActive('/thoughts') ? 'active' : ''}`}
                onClick={nextPage}>
                {menu.item_5}
              </Link>
            </li>
          </nav>
          <ul className="menu-info column col-3">
            <li dangerouslySetInnerHTML={{ __html: headerInfo.info_1 }} />
            <li dangerouslySetInnerHTML={{ __html: headerInfo.info_2 }} />
            <li dangerouslySetInnerHTML={{ __html: headerInfo.info_3 }} />
            <li>
              <p>
                <Link
                  to={social.link_1}
                  target="_blank">
                  {social.social_1}
                </Link>
              </p>
              <p>
                <Link
                  to={social.link_2}
                  target="_blank">
                  {social.social_2}
                </Link>
              </p>
              <p>
                <Link
                  to={social.link_3}
                  target="_blank">
                  {social.social_3}
                </Link>
              </p>
            </li>
            <li>
              <p className="real-time">{time}</p>
              <p>{date}</p>
            </li>
          </ul>
          <form
            className="menu-form column col-3"
            onSubmit={handleSubmit(handleSubmitProject)}
            noValidate>
            <p>
              Get in touch so we can discuss how to create a bold, unique, and
              emotion brand.
            </p>
            <div className="menu-input">
              <label for="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Name"
                {...register('name')}
              />
              {errors.name && (
                <span className="error">{errors.name.message}</span>
              )}
            </div>
            <div className="menu-input">
              <label for="email">Your Email (required)</label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                {...register('email')}
              />
              {errors.email && (
                <span className="error">{errors.email.message}</span>
              )}
            </div>
            <span>Checkbox</span>
            <label
              for="project"
              className="checkbox-input">
              <input
                type="checkbox"
                id="project"
                {...register('project')}
              />
              <div class="checkmark"></div>
              <span>Project enquiries</span>
            </label>
            <label
              for="collaborations"
              className="checkbox-input">
              <input
                type="checkbox"
                id="collaborations"
                {...register('collaborations')}
              />
              <div class="checkmark"></div>
              <span>Collaborations</span>
            </label>
            <label
              for="something"
              className="checkbox-input">
              <input
                type="checkbox"
                id="something"
                {...register('something')}
              />
              <div class="checkmark"></div>
              <span>Something else</span>
            </label>
            <button className="menu-button">
              <span>Submit</span>
            </button>
          </form>
          <DevTool control={control} />
          <div className="menu-logo column col-2">
            <Link
              to="/"
              onClick={nextPage}>
              <img
                src={headerMeta.logo}
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>
      <div class={`success-modal ${isSubmit ? 'active' : ''}`}>
        <div class="success-content">
          <h2>Success!</h2>
          <p>
            Thanks for connecting with Leo! Our team will be in touch within 24
            hour to discuss your request further.{' '}
          </p>
          <div
            className="success-icon"
            onClick={closeModal}>
            <i class="fa-solid fa-xmark"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
